<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar flat>
      <v-icon left>mdi-view-column</v-icon>
      <v-toolbar-title>TABLERO DE OPORTUNIDADES</v-toolbar-title>
      <v-divider class="mx-4" vertical></v-divider>
      <v-col cols="3">
        <v-select
          label="pipeline"
          :items="pipelines"
          item-text="pipeline_name"
          item-value="pipeline_id"
          v-model="pipeline_id"
          @change="cargarDatosKanban()"
        ></v-select>
      </v-col>
    </v-toolbar>
    <div class="kanban  scrollbar-default">
      <v-col
        v-for="(column, idx) in etapas"
        :key="column.opportunity_stage_id"
        :cols="12 / (etapas.length || 1)"
        class="card-column elevation-1 pb-4 "
        :style="
          idx == 112 || idx == 114
            ? `min-width:60px; max-width:60px`
            : `min-width:300px;`
        "
      >
        <v-toolbar
          v-if="idx == 112 || idx == 114"
          flat
          class="mb-1"
          dark
          height="490px"
          rounded
          fixed
          :color="colores[idx]"
        >
          <span
            class="col-vertical"
            style="   font-weight:bold; overflow: hidden; white-space: nowrap; "
            >{{ column.description }}
          </span>
        </v-toolbar>
        <v-toolbar
          v-if="idx != 112 && idx != 114"
          flat
          class="mb-1"
          dark
          height="35"
          rounded
          fixed
          style="font-weight: bold;"
          :color="colores[idx]"
          ><span
            :title="`${column.description}`"
            style="width:83%; font-weight:bold; overflow: hidden; white-space: nowrap; "
            >{{ column.description }}</span
          >
          <v-divider vertical class="mx-2"></v-divider>

          <div v-if="op_totales != null">
            {{ op_totales[column.opportunity_stage_id][0].num_oportunidades }}
          </div>
          <v-spacer></v-spacer>
          <v-divider vertical class="mx-2"></v-divider>
          <div v-if="op_totales != null">
            ${{ op_totales[column.opportunity_stage_id][0].total_op }}
          </div>
        </v-toolbar>

        <div
          id="tablero"
          :data-id="column.opportunity_stage_id"
          class="card-list pa-2 scrollbar-default"
          style="max-height:500px; overflow-y:auto;"
        >
          <v-card
            v-for="(oport, index) in oportunidades[column.opportunity_stage_id]"
            :key="oport.sales_opportunity_id"
            :color="
              oport.opportunity_stage_id == 'SOSTG_CLOSED'
                ? 'grey lighten-2'
                : 'white'
            "
            class="mb-3 "
            small
            min-width="250"
          >
            <v-card-title>
              <span
                style="width:85%; line-height:26px; height:30px; overflow: hidden; white-space: nowrap; "
                >{{ oport.cliente_nombre }}</span
              >

              <v-spacer></v-spacer>

              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" v-if="oport.stage_type != 'FINAL11'">
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <!-- <v-menu bottom right> -->
                    <!-- <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on"
                          ><v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                      </template> -->
                    <v-list small>
                      <v-list-item
                        style="background-color:rgb(76, 176, 83); color:white; margin:0px; min-height: 24;  "
                      >
                        <v-list-item-title
                          style="background-color:rgb(76, 176, 80); color:white; margin:0px; min-height: 24; "
                        >
                          <v-icon small left dark>mdi-swap-horizontal</v-icon
                          >Mover a
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        small
                        style="min-height:36px"
                        v-if="
                          etapato.opportunity_stage_id !=
                            column.opportunity_stage_id
                        "
                        v-for="(etapato, index) in etapas"
                        :key="etapato.opportunity_stage_id"
                      >
                        <v-btn
                          @click="
                            cambiarEstado(oport, etapato.opportunity_stage_id)
                          "
                          small
                          text
                        >
                          {{ etapato.description }}</v-btn
                        >
                      </v-list-item>
                    </v-list>
                    <!-- </v-menu> -->
                  </v-list-item>
                  <!-- <v-list-item>
                    <v-list-item-title>Eliminar</v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-text>
              <v-btn
                small
                text
                target="_blank"
                :to="`/oportunidad_form/${oport.sales_opportunity_id}`"
                ><v-icon small left>mdi-cash</v-icon>${{
                  oport.estimated_amount
                }}</v-btn
              >
              <v-btn
                small
                text
                target="_blank"
                :to="
                  `/crm_cotizacion?accion=crear_op&sop_id=${oport.sales_opportunity_id}`
                "
                ><v-icon small left>mdi-offer</v-icon
                >{{ oport.quote_id }}</v-btn
              >
            </v-card-text>
            <v-card-actions v-if="oport.opportunity_stage_id == 'SOSTG_CLOSED'">
              <v-spacer />
              <v-btn icon @click="openUnlockRequest(oport)">
                <v-icon small>mdi-lock</v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-actions v-else>
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn icon small color="info" v-on="on">
                    <v-icon small>mdi-information-outline</v-icon>
                  </v-btn>
                </template>

                <v-card min-width="300" max-width="300" class="pt-0">
                  <v-list class="pt-0">
                    <v-alert
                      color="blue"
                      dark
                      height="20"
                      class="pt-0 pb-6 mt-0 "
                      small
                      >Guia</v-alert
                    >

                    <v-list-item
                      style="font-size: 79%; padding-top:0px"
                      v-html="column.guia"
                    >
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>
                </v-card>
              </v-menu>
              <v-spacer />

              <v-btn
                icon
                target="_blank"
                :to="
                  `/evento_op/${oport.sales_opportunity_id}/${oport.party_id}`
                "
              >
                <v-icon small>mdi-calendar-plus</v-icon>
              </v-btn>
              <!-- <v-menu top left>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="deleteTask(oport, column, index)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click.prevent>
                    <v-list-item-title>Cancel</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu> -->
            </v-card-actions>
          </v-card>
        </div>
        <!-- <v-btn fab small absolute dark color="pink" class="card-add-button">
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->
      </v-col>
    </div>
    <!-- <v-data-table
      :headers="headers"
      :items-per-page="itemsPerPage"
      :items="oportunidades.SOSTG_PROSPECT"
      class="elevation-1"
      small
      hide-default-footer
    >
      <template v-slot:item.sales_opportunity_id="{ item }">
        <v-btn
          text
          small
          @click="abrirOportunidadDialog(item.sales_opportunity_id)"
        >
          {{ item.sales_opportunity_id }}
        </v-btn>
      </template>

      <template v-slot:footer>
        <v-pagination
          class="mt-10"
          v-model="currentPage"
          :length="pageCount"
          @input="handlePageChange"
          total-visible="10"
        ></v-pagination>
      </template>
    </v-data-table> -->
    <v-dialog v-model="dialogoOportunidad" fullscreen>
      <v-card>
        <v-toolbar dark color="blue" height="40" flat>
          Oportunidad # {{ datosOportunidad.sales_opportunity_id }} ::
          {{ datosOportunidad.opportunity_name }}
          <v-spacer></v-spacer>
          <v-btn small text @click="dialogoOportunidad = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="mt-1 mx-2">
          <v-col cols="2">
            <v-text-field
              label="Cliente ID"
              v-model="datosOportunidad.party_id"
            ></v-text-field>
          </v-col>

          <v-col cols="2">
            <v-text-field
              label="Monto"
              v-model="datosOportunidad.monto"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Fecha de cierre"
              v-model="datosOportunidad.fecha_estimada_cierre"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Cliente"
              v-model="datosOportunidad.cliente_nombres"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-1 mx-2">
          <v-col>
            <v-text-field
              label="Estado"
              v-model="datosOportunidad.estado"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Nombre"
              v-model="datosOportunidad.opportunity_name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-1 mx-2">
          <v-col>
            <v-text-field
              label="Descripcion"
              v-model="datosOportunidad.description"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card>
          <v-row class="mt-1 mx-2">
            <v-col>
              <v-data-table
                :headers="headersItems"
                :items-per-page="itemsPerPage"
                :items="productosOp"
                class="elevation-1"
                small
                hide-default-footer
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
#tablero .v-card > .v-card__title {
  font-size: 85%;
  color: black;
  padding: 6px;
  margin: 0px;
  line-height: 90%;
}

#tablero .v-card > .v-card__text {
  font-size: 85%;
  padding-left: 6px;
}
.col-vertical {
  height: 36px;
  line-height: 36px;
  margin: 0;
  padding: 0 8px 0 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: -4px;
  transform: rotate(-90deg);
  transform-origin: 100% 100%;
  width: 526px;
}
.kanban {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  min-height: 200px;

  /*height: 100%;
  height: fit-content;*/

  max-height: 520px;
  overflow-x: scroll;
}

.scrollbar-default::-webkit-scrollbar {
  width: 0.45rem;
  height: 0.45rem;
  /*background: inherit;*/
}
.scrollbar-default::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
  background-color: #d4d4d7;
}
</style>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
 

export default {
  name: "OportunidadKanban",
  data: () => ({
    show: false,

    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    setfilters: [],
    selected: [],
    users: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 50,
    pageCount: 1,
    overlay: false,
    colores: ["blue", "#edd100", "gray", "orange", "green", "brown", "purple"],
    /*columnas: [
      { id: "SOSTG_PROSPECT", titulo: "Prospeccion", color: "blue" },
      { id: "SOSTG_READY_TOCLOSE", titulo: "Por cerrar", color: "yellow" },
      { id: "SOSTG_CLOSED_REF", titulo: "Inactiva", color: "gray" },
      { id: "SOSTG_CLOSED_XX", titulo: "Cita Agendada", color: "orange" },
      { id: "SOSTG_CLOSED", titulo: "Cerrada ganada", color: "green" },
      { id: "SOSTG_CLOSED_LOST", titulo: "Cerrada perdida", color: "brown" },
    ],*/
    etapas: [],

    required: [(v) => !!v || "El campo es requerido"],

    headers: [
      { text: "ID", align: "start", value: "sales_opportunity_id" },
      { text: "Nombre", align: "start", value: "opporunity_name" },
      { text: "Cliente", align: "start", value: "cliente_nombre" },
      { text: "Estado", align: "start", value: "sopp_estado" },
      { text: "Monto", align: "start", value: "estimated_amount" },
      {
        text: "Cierre estimado",
        align: "start",
        value: "estimated_close_date",
      },
      { text: "Asignado a", align: "start", value: "created" },
    ],
    oportunidades: [],
    op_totales: null,
    dialogoOportunidad: false,
    datosOportunidad: {
      party_id: "",
      monto: 0,
      fecha_estimada_cierre: "",
      estado_id: "",
      estado: "",
      opportunity_name: "",
      cliente_nombres: "",
      description: "",
      next_step: "",
    },
    productosOp: [],
    pipeline_id: "",
    pipelines: [],
    headersItems: [
      { text: "ID", align: "start", value: "seq_id" },
      { text: "Producto", align: "start", value: "product_name" },
      { text: "Cantidad", align: "start", value: "cantidad" },
      { text: "Valor unitario", align: "start", value: "precio" },
      { text: "Descuento", align: "start", value: "valor_descuento" },
      { text: "Total", align: "start", value: "total" },
    ],
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
      "setDrawer",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),

    cargarListaOportunidades(pipId) {
      this.setLoadingTable(true);
      this.setUrl("oportunidad");
      this.requestApi({
        method: "GET",
        data: {
          view_type: "kanban",
          pipeline_id: pipId,
        },
      })
        .then((res) => {
          //console.log(res.data.detail);
          this.oportunidades = res.data.detail.columnas;
          this.op_totales = res.data.detail.col_totales;
          console.log(res.data.detail);
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    /* abrirOportunidadDialog(opId) {
      this.dialogoOportunidad = true;
      this.cargarDatosOportunidad(opId);
    },*/

    /*cargarDatosOportunidad(id) {
      this.setLoadingTable(true);
      this.setUrl("oportunidad/" + id);
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          console.log(res.data.detail);
          this.datosOportunidad.party_id = res.data.detail.oportunidad.party_id;
          this.productosOp = res.data.detail.items_op;
          this.datosOportunidad.monto =
            res.data.detail.oportunidad.estimated_amount;
          this.datosOportunidad.estado = res.data.detail.oportunidad.op_estado;
          this.datosOportunidad.fecha_estimada_cierre =
            res.data.detail.oportunidad.estimated_close_date;
          this.datosOportunidad.cliente_nombres =
            res.data.detail.oportunidad.cliente_nombre;
          this.datosOportunidad.opportunity_name =
            res.data.detail.oportunidad.opportunity_name;
          this.datosOportunidad.description =
            res.data.detail.oportunidad.description;
          this.datosOportunidad.next_step =
            res.data.detail.oportunidad.next_step;
          this.datosOportunidad.sales_opportunity_id =
            res.data.detail.oportunidad.sales_opportunity_id;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },*/
    cargarDatosKanban() {
      this.overlay = true;
      this.setUrl("oportunidad");
      this.requestApi({
        method: "GET",
        data: {
          view_type: "datosKanban",
          pipeline_id: this.pipeline_id,
        },
      })
        .then((res) => {
          //console.log(res.data);
          //this.datosEtapa = res.data.;
          this.etapas = res.data.detail.data_pl.etapas;
          this.pipelines = res.data.detail.lista_pl;
          if (this.pipeline_id == "") {
            this.pipeline_id = this.pipelines[0].pipeline_id;
          }

          this.cargarListaOportunidades(this.pipeline_id);
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    cambiarEstado(sop, estadoA) {
      this.setUrl("oportunidad");
      this.requestApi({
        method: "POST",
        data: {
          accion: "cambiarEstadoA",
          sales_opportunity_id: sop.sales_opportunity_id,
          status_id: estadoA,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.cargarListaOportunidades(this.pipeline_id);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    handlePageChange(value) {
      this.cargarDatos();
    },
  },
  mounted() {
    //this.cargarListaOportunidades();
    this.setDrawer(false);
    this.setTitleToolbar("OPORTUNIDADES");
    this.cargarDatosKanban();
  },
};
</script>

<style></style>