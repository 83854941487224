import { render, staticRenderFns } from "./OportunidadKanban.vue?vue&type=template&id=309c8748"
import script from "./OportunidadKanban.vue?vue&type=script&lang=js"
export * from "./OportunidadKanban.vue?vue&type=script&lang=js"
import style0 from "./OportunidadKanban.vue?vue&type=style&index=0&id=309c8748&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports